import { useCallback, useContext } from "react";

import { AuthContext } from "../components/AuthContextProvider";
import { DropdownItemProps } from "semantic-ui-react";
import { Role } from "../types";
import { toast } from "react-toastify";
import { tradingClientID } from "../services/Constants";
import useDatabroker from "./useDatabroker";

interface UseDataServiceOutput {
    getClientOptions: () => Promise<DropdownItemProps[] | undefined>;
    getPortfolioOptions: () => Promise<DropdownItemProps[] | undefined>;
}

const useDataService = (): UseDataServiceOutput => {
    const databroker = useDatabroker();
    const { userHasRole } = useContext(AuthContext);

    const getClientOptions = useCallback(async (): Promise<DropdownItemProps[] | undefined> => {
        try {
            const { data, error } = await databroker.search<{ ClientID: number; NAME: string; Enabled: boolean }>({
                objectName: "MedDiag.dbo.tClient",
                select: ["ClientID", "NAME"],
                where: { Enabled: true, NAME: { notEqual: "", null: false } },
                orderBy: [["NAME", "ASC"]]
            });
            if (error || !data) throw new Error(error);
            const options = data.map((item) => ({
                key: item.ClientID,
                value: item.ClientID,
                text: `${item.NAME} - ${item.ClientID}`
            }));
            return userHasRole(Role.ServicingTrading) ? options : options.filter((item) => item.value !== tradingClientID);
        } catch (error) {
            console.error(error);
            toast.error(`Error fetching clients`);
        }
    }, [databroker, userHasRole]);

    const getPortfolioOptions = useCallback(async (): Promise<DropdownItemProps[] | undefined> => {
        try {
            const { data, error } = await databroker.search<{
                PortfolioID: number;
                PortfolioName: string;
                Active: boolean;
                ClientID: number;
            }>({
                objectName: "MedDiag.PM.Portfolio",
                select: ["PortfolioID", "PortfolioName", "ClientID"],
                where: { Active: true },
                orderBy: [["PortfolioName", "ASC"]]
            });
            if (error || !data) throw new Error(error);
            const options = data.map((item) => ({
                clientid: item.ClientID, // React does not recognize the `ClientID` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `clientid` instead.
                key: item.PortfolioID,
                value: item.PortfolioID,
                text: `${item.PortfolioName} - ${item.PortfolioID}`
            }));
            return options;
        } catch (error) {
            console.error(error);
            toast.error(`Error fetching portfolios`);
        }
    }, [databroker]);

    return { getClientOptions, getPortfolioOptions };
};

export default useDataService;
