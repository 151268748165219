import "semantic-ui-css/semantic.min.css";
import "@itm21st/navbar/dist/style.css";
import "@itm21st/faceted-filter/dist/style.css";
import "react-toastify/dist/ReactToastify.css";
import "./styles/kendo.scss";
import "./styles/styles.scss";

import { Link, Router } from "react-router-dom";
import React, { useContext } from "react";

import AppRoutes from "./AppRoutes";
import { AuthContext } from "./components/AuthContextProvider";
import { Grid } from "semantic-ui-react";
import History from "./services/History";
import IdleTimeout from "@itm21st/idle-timeout-react";
import Navbar from "@itm21st/navbar";
import QuickSearch from "./components/QuickSearch";
import { ToastContainer } from "react-toastify";
import config from "./config";
import { getToken } from "./services/StorageProvider";

const App: React.FC = () => {
    const { user, logout } = useContext(AuthContext);
    return (
        <Router history={History}>
            <IdleTimeout onIdle={logout} />
            {!!user && (
                <>
                    <div style={{ paddingBottom: "3px", backgroundColor: "white", position: "sticky", width: "100%", zIndex: 4, top: 0 }}>
                        <Navbar
                            siteMapApiUrl={config.siteMapApiUrl}
                            host="servicing"
                            accessToken={getToken() as string}
                            linkComponent={Link as React.ElementType<{ to: string }>}
                            username={user.username}
                            logout={logout}
                        >
                            <Grid>
                                <Grid.Column width={8} floated="right" textAlign="right">
                                    <QuickSearch />
                                </Grid.Column>
                            </Grid>
                        </Navbar>
                    </div>
                </>
            )}

            <Grid padded="horizontally">
                <Grid.Row centered>
                    <Grid.Column tablet={16} largeScreen={16} widescreen={16}>
                        <ToastContainer theme="colored" />
                        <AppRoutes />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Router>
    );
};

export default App;
