import axios from "axios";
import config from "../config";
import cookie from "js-cookie";

const { accessTokenName } = config;

export const storeToken = (value: string): void => {
    cookie.set(accessTokenName, value);
};

export const getToken = (): string | null => {
    const token = cookie.get(accessTokenName);
    if (token) {
        return token;
    }
    return null;
};

export const clearToken = (): void => {
    cookie.remove(accessTokenName);
};

export const getFileApiCookie = async (): Promise<void> => {
    try {
        await axios.get(`${config.fileApiUrl}/cookie`, {
            headers: { Authorization: `Bearer ${getToken() as string}` },
            withCredentials: true
        });
    } catch (error) {
        console.log(error);
    }
};
