import { Divider, Grid, Header, SemanticICONS } from "semantic-ui-react";
import React, { ReactNode } from "react";

interface PageHeaderProps {
    title: string;
    icon?: SemanticICONS;
    rightContent?: ReactNode;
    divider?: boolean;
}

const PageHeader: React.FC<PageHeaderProps> = (props: PageHeaderProps) => {
    const { title, icon, rightContent, divider = true } = props;

    return (
        <>
            <Grid>
                <Grid.Row verticalAlign="middle">
                    <Grid.Column width="8">
                        <Header color="blue" icon={icon} content={title} />
                    </Grid.Column>
                    <Grid.Column width="8" textAlign="right">
                        {rightContent}
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {divider && <Divider style={{ marginTop: "0.5rem" }} />}
        </>
    );
};

export default PageHeader;
