import { useEffect, useState } from "react";
import { defaultSiteTitle } from "./Library";

export const useTitle = (initialTitle?: string): React.Dispatch<React.SetStateAction<string | undefined>> => {
    const [title, setTitle] = useState(initialTitle);
    useEffect(() => {
        const htmlTitle = document.querySelector("title");
        if (htmlTitle) {
            htmlTitle.innerText = title || defaultSiteTitle;
            return () => {
                htmlTitle.innerText = defaultSiteTitle;
            };
        }
    }, [title]);
    return setTitle;
};
