import App from "./App";
import AuthContextProvider from "./components/AuthContextProvider";
import React from "react";
import ReactDOM from "react-dom";
import config from "./config";
import qs from "qs";
import { storeToken } from "./services/StorageProvider";

const { accessTokenName } = config;
const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
const existingToken = query[accessTokenName] as string;
if (existingToken) {
    storeToken(existingToken);
    delete query[accessTokenName];
    window.location.search = qs.stringify(query);
}

ReactDOM.render(
    <React.StrictMode>
        <AuthContextProvider>
            <App />
        </AuthContextProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
