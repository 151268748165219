/* eslint-disable @typescript-eslint/no-var-requires */
const env = process.env.REACT_APP_ENVIRONMENT || "dev";
const rootConfig = require("./config.json");
const envConfig = require(`./config.${env}.json`);

const config: {
    accessTokenName: string;
    authenticateApiUrl: string;
    authorizationCodeName: string;
    authWebUrl: string;
    connectWebUrl: string;
    databrokerApiUrl: string;
    deathEngineApiUrl: string;
    emailApiUrl: string;
    fastClientID: string;
    fasanoClientUrl: string;
    fasanoInternalUrl: string;
    faxApiUrl: string;
    fileApiUrl: string;
    /** The Auth0 application id for the Lexserv client web app */
    lexservClientID: string;
    lexservWebUrl: string;
    /** The Auth0 application id for the Longevity client web app */
    longevityClientID: string;
    longevityWebUrl: string;
    policyValuationApiUrl: string;
    servicingPdfApiUrl: string;
    siteMapApiUrl: string;
    servicingReportApiUrl: string;
    cloudApiBaseUrl: string;
    twentyFirstLEClientID: string;
    uwWebUrl: string;
} = {
    ...rootConfig,
    ...envConfig
};

export default config;
