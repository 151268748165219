import { useEffect, useState } from "react";

const useDebounce = <T>(value: T, delayMs: number): T => {
    const [state, setState] = useState<T>(value);

    useEffect(() => {
        const timeoutHandle = setTimeout(() => setState(value), delayMs);
        return () => clearTimeout(timeoutHandle);
    }, [value, delayMs]);

    return state;
};

export default useDebounce;
