import { SemanticCOLORS, SemanticICONS } from "semantic-ui-react";

export enum Role {
    Accounting = "Accounting",
    AccountingAdmin = "AccountingAdmin",
    GenericAdmin = "Admin",
    MedRecMgr = "MedRecMgr",
    ServicingOptReview = "ServicingOptReview",
    ServicingInsuredTrackingAdmin = "ServicingInsuredTrackingAdmin",
    ServicingPolicyManager = "ServicingPolicyManager",
    ServicingUser = "Servicing",
    ServicingAdmin = "ServicingAdmin",
    ServicingTrading = "ServicingTrading",
    UWSupportSr = "UWSupportSr",
    EmployeeViewSSN = "EmployeeViewSSN"
}

export interface Client {
    ClientID: number;
    ClientName: string;
}

export interface Portfolio {
    ClientID: number;
    ClientName: string;
    PortfolioID: number;
    PortfolioName: string;
}

export interface ClientInsured {
    ClientID: number;
    InsuredID: number;
    ClientTrackingID: string;
    CaseManager: string;
    Tracking: boolean;
    Notes: string;
    ObitSweep: boolean;
    ClientInsuredID?: number;
    CreatedDate?: Date | string;
    DeathNotificationDate?: Date | string | null;
    CreatedBy?: string;
    UpdatedDate?: Date | string;
    UpdatedBy?: string;
}

export interface FileType {
    FileTypeID: number;
    Name: string;
    InsuredDoc?: boolean;
    PolicyDoc?: boolean;
    PortfolioDoc?: boolean;
}

export interface GenericFile {
    highlighted?: boolean;
    selected?: boolean;
}

export type pane = {
    menuItem: {
        className?: string;
        color: SemanticCOLORS;
        content: string;
        icon: SemanticICONS;
        key: string;
    };
    render: () => React.ReactNode;
};

export interface HipaaTemplate {
    LetterType: string;
    LetterSubType: string;
    DisplayName: string;
    DoctorsRequired?: boolean;
    ContactRequired?: boolean;
    Checked: boolean;
}

export const HipaaTemplateList: HipaaTemplate[] = [
    {
        LetterType: "hipaacoverletter",
        LetterSubType: "",
        DisplayName: "HIPAA Cover letter",
        Checked: false
    },
    {
        LetterType: "hipaacoverletter",
        LetterSubType: "Designate",
        DisplayName: "HIPAA Cover letter (Designate)",
        ContactRequired: true,
        Checked: false
    },
    {
        LetterType: "hipaacoverletter",
        LetterSubType: "Special Release",
        DisplayName: "HIPAA Cover letter (Special Release)",
        DoctorsRequired: true,
        Checked: false
    },
    {
        LetterType: "hipaacoverletter",
        LetterSubType: "Special Release & Designate",
        DisplayName: "HIPAA Cover letter (Special Release & Designate)",
        DoctorsRequired: true,
        ContactRequired: true,
        Checked: false
    },
    {
        LetterType: "hipaaform",
        LetterSubType: "",
        DisplayName: "LexServ HIPAA Form",
        Checked: true
    },
    {
        LetterType: "physicianlist",
        LetterSubType: "",
        DisplayName: "LexServ Physician List",
        Checked: true
    }
];

export interface PortfolioSpecificHipaaTemplate extends HipaaTemplate {
    PortfolioID: number;
}

export const PortfolioSpecificHipaaTemplates: PortfolioSpecificHipaaTemplate[] = [
    {
        LetterType: "portfolio-specific-hipaaform",
        LetterSubType: "Emerald",
        PortfolioID: 857,
        Checked: false,
        DisplayName: "Emerald HIPAA"
    },
    {
        LetterType: "portfolio-specific-hipaaform",
        LetterSubType: "Firethorn",
        PortfolioID: 910,
        Checked: false,
        DisplayName: "Firethorn HIPAA"
    },
    { LetterType: "portfolio-specific-hipaaform", LetterSubType: "Juno", PortfolioID: 816, Checked: false, DisplayName: "Juno HIPAA" },
    { LetterType: "portfolio-specific-hipaaform", LetterSubType: "Juno", PortfolioID: 858, Checked: false, DisplayName: "Juno HIPAA" },
    { LetterType: "portfolio-specific-hipaaform", LetterSubType: "LSH", PortfolioID: 817, Checked: false, DisplayName: "LSH HIPAA" },
    {
        LetterType: "portfolio-specific-hipaaform",
        LetterSubType: "RoadK",
        PortfolioID: 818,
        Checked: false,
        DisplayName: "Road K (LS) Insured HIPAA"
    },
    {
        LetterType: "portfolio-specific-hipaaform",
        LetterSubType: "Sequoia",
        PortfolioID: 837,
        Checked: false,
        DisplayName: "Sequoia HIPAA"
    },
    {
        LetterType: "portfolio-specific-hipaaform",
        LetterSubType: "Sequoia",
        PortfolioID: 838,
        Checked: false,
        DisplayName: "Sequoia HIPAA"
    },
    {
        LetterType: "portfolio-specific-hipaaform",
        LetterSubType: "Sequoia",
        PortfolioID: 819,
        Checked: false,
        DisplayName: "Sequoia HIPAA"
    },
    {
        LetterType: "portfolio-specific-hipaaform",
        LetterSubType: "Sequoia",
        PortfolioID: 855,
        Checked: false,
        DisplayName: "Sequoia HIPAA"
    },
    {
        LetterType: "portfolio-specific-hipaaform",
        LetterSubType: "Teocalli",
        PortfolioID: 821,
        Checked: false,
        DisplayName: "Teocalli HIPAA"
    },
    { LetterType: "portfolio-specific-hipaaform", LetterSubType: "Viva3", PortfolioID: 822, Checked: false, DisplayName: "Viva 3 HIPAA" },
    { LetterType: "portfolio-specific-hipaaform", LetterSubType: "Viva4", PortfolioID: 823, Checked: false, DisplayName: "Viva 4 HIPAA" },
    {
        LetterType: "portfolio-specific-hipaaform",
        LetterSubType: "VivaCap",
        PortfolioID: 824,
        Checked: false,
        DisplayName: "Viva Cap Trust HIPAA"
    },
    { LetterType: "portfolio-specific-hipaaform", LetterSubType: "Viva3", PortfolioID: 513, Checked: false, DisplayName: "Viva 3 HIPAA" },
    { LetterType: "portfolio-specific-hipaaform", LetterSubType: "Viva4", PortfolioID: 563, Checked: false, DisplayName: "Viva 4 HIPAA" }
];
