import { Button, Divider, DropdownItemProps, Form, Grid, Header, Message, Table } from "semantic-ui-react";
import React, { useEffect, useState } from "react";

import { PRESTON_CLIENT_ID } from "../../services/Constants";
import PageHeader from "../../components/PageHeader";
import PolicyLink from "../../components/PolicyLink";
import { Portfolio } from "../portfolio/Types";
import { toIsoDateString } from "../../services/Library";
import { toast } from "react-toastify";
import useDataService from "../../hooks/useDataService";
import useDatabroker from "../../hooks/useDatabroker";
import { useTitle } from "../../services/SetTitle";

interface PolicyCopyMap {
    OldPolicyID: number;
    NewPolicyID: number;
}

const PolicyCopy: React.FC = () => {
    const [clientOptions, setClientOptions] = useState<DropdownItemProps[]>();
    const [copying, setCopying] = useState<boolean>(false);
    const [destinationPortfolioID, setDestinationPortfolioID] = useState<number>();
    const [exclude, setExclude] = useState<boolean>(false);
    const [fetchingPorts, setFetchingPorts] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [markSold, setMarkSold] = useState<boolean>(true);
    const [policyResultMap, setPolicyResultMap] = useState<PolicyCopyMap[]>();
    const [policyIDs, setPolicyIDs] = useState<number[]>([]);
    const [policySearchData, setPolicySearchData] = useState<string>();
    const [portfolioOptions, setPortfolioOptions] = useState<DropdownItemProps[]>();
    const [selectedClientID, setSelectedClientID] = useState<number>();
    const [serviceEndDate, setServiceEndDate] = useState<string>("");
    const [serviceStartDate, setServiceStartDate] = useState<string>("");
    const [useEnd, setUseEnd] = useState<boolean>(true);
    const [useStart, setUseStart] = useState<boolean>(true);
    const { getClientOptions } = useDataService();
    const databroker = useDatabroker();
    useTitle("Policy Copy");

    useEffect(() => {
        if (clientOptions) return;
        (async () => setClientOptions((await getClientOptions()) || []))();
    }, [clientOptions, getClientOptions]);

    useEffect(() => {
        selectedClientID === PRESTON_CLIENT_ID && setExclude(true);
    }, [selectedClientID]);

    const getPortfolioOptions = async (clientID: number) => {
        setFetchingPorts(true);
        const { data, error } = await databroker.query<Portfolio>({
            select: ["port.ClientID", "port.PortfolioID", "port.PortfolioName"],
            from: { objectName: "MedDiag.PM.Portfolio", as: "port" },
            join: [{ from: { objectName: "MedDiag.dbo.tClient", as: "c" }, on: ["c.ClientID", "port.ClientID"] }],
            where: { "port.ClientID": clientID },
            orderBy: [["PortfolioName", "ASC"]]
        });
        setFetchingPorts(false);
        if (error || !data) throw new Error(error);
        const options = data.map((item) => ({
            key: item.PortfolioID,
            value: item.PortfolioID,
            text: `${item.PortfolioName} - ${item.PortfolioID}`
        }));
        setPortfolioOptions(options);
    };

    const checkPolicies = async () => {
        if (!policySearchData || !portfolioOptions) return;

        let tempPolicyIDs: number[] = [];
        try {
            tempPolicyIDs = policySearchData
                .replace(/[,\s]+/g, ",")
                .split(",")
                .map((item) => +item);
            if (tempPolicyIDs.filter(Number).length !== tempPolicyIDs.length) {
                throw new Error();
            }
            if (tempPolicyIDs.length >= 100) {
                toast.error(`Maximum is 100 policies`);
                return;
            }
        } catch (error) {
            toast.error(`One or more PolicyIDs are invalid`);
            return;
        }

        setLoading(true);

        const { error, data } = await databroker.search<{ PolicyID: number; PortfolioID: number }>({
            select: ["PolicyID", "PortfolioID"],
            objectName: "MedDiag.PM.Policy",
            where: { PolicyID: { in: tempPolicyIDs } },
            orderBy: [["PolicyID", "ASC"]]
        });

        setLoading(false);

        if (error || !data) {
            toast.error(`Error checking policies`);
            return;
        }
        if (data.length !== tempPolicyIDs.length) {
            toast.error(`One or more policies not found`);
            return;
        }

        const clientPortfolioIDs = portfolioOptions.map((item) => item.value as number);
        const policyPortfolioIDs = data.map((item) => item.PortfolioID);
        const notFound = policyPortfolioIDs.filter((item) => clientPortfolioIDs.indexOf(item) === -1);

        if (notFound.length) {
            toast.error(`One or more policies belong to another client`);
            return;
        }

        const sourcePortfolios = Array.from(new Set(data.map((item) => item.PortfolioID)));

        if (sourcePortfolios.length > 1) {
            toast.error(`All policies must be from same portfolio`);
            return;
        }
        if (sourcePortfolios[0] === destinationPortfolioID) {
            toast.error(`Source and destination portfolios must be different`);
            return;
        }

        setPolicyIDs(data.map((item) => item.PolicyID));
    };

    const doPolicyCopy = async () => {
        if (!destinationPortfolioID || !policyIDs.length) return;
        setCopying(true);
        const policyIDList = policyIDs.map((item) => ({ col1: item.toString() }));
        try {
            const { error, data } = await databroker.sproc<PolicyCopyMap>({
                objectName: "MedDiag.PM.PolicyCopy",
                parameters: {
                    PolicyIDList: policyIDList,
                    NewPortfolioID: destinationPortfolioID,
                    ServiceStartDate: serviceStartDate || null,
                    ServiceEndDate: serviceEndDate || null,
                    MarkSold: markSold,
                    ExcludeFilesFromAPI: exclude,
                    UserID: "USERID"
                }
            });
            if (error || !data) {
                throw new Error(error);
            }
            setPolicyResultMap(data);
            toast.success(`Policies copied`);
        } catch (error) {
            toast.error(`Error copying policies`);
            console.error(error);
        } finally {
            setCopying(false);
            // force re-enable triggers if above call fails
            const { data: triggerData } = await databroker.sproc({ objectName: "MedDiag.PM.EnableGraceTriggers" });
            console.log(triggerData);
        }
    };

    const handleCSVExport = () => {
        if (!policyResultMap) return;
        const csv = [...policyResultMap].map((row) => Object.values(row));
        csv.unshift(["OldPolicyID", "NewPolicyID"]);
        const output = csv.map((item) => `${item.join(",")}\n`);
        const blob = new Blob(output, { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Policy Copy - ${toIsoDateString(new Date())}.csv`;
        link.click();
    };

    return (
        <>
            <div className="mt-2 mb-2">
                <PageHeader title="Policy Copy" icon="copy outline" divider={false} />
            </div>

            <Form onSubmit={doPolicyCopy} size="tiny">
                <Grid padded="horizontally">
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Form.Dropdown
                                width={12}
                                disabled={!clientOptions}
                                label="Client"
                                onChange={async (_e, { value }) => {
                                    if (policyIDs.length) return;
                                    const val = value as number;
                                    setSelectedClientID(val);
                                    setDestinationPortfolioID(undefined);
                                    await getPortfolioOptions(val);
                                }}
                                openOnFocus={false}
                                options={clientOptions || []}
                                placeholder="Select a client"
                                required
                                scrolling
                                search
                                selection
                                selectOnBlur={false}
                                value={selectedClientID || ""}
                            />
                            {selectedClientID && (
                                <Form.Dropdown
                                    disabled={!portfolioOptions || fetchingPorts}
                                    width={12}
                                    label="Destination Portfolio"
                                    loading={fetchingPorts}
                                    onChange={(_e, { value }) => {
                                        if (policyIDs.length) return;
                                        setDestinationPortfolioID(value as number);
                                    }}
                                    openOnFocus={false}
                                    options={portfolioOptions || []}
                                    placeholder="Select a destination portfolio"
                                    required
                                    scrolling
                                    search
                                    selection
                                    selectOnBlur={false}
                                    value={destinationPortfolioID || ""}
                                />
                            )}
                            {selectedClientID && destinationPortfolioID && (
                                <Form.TextArea
                                    label="PolicyIDs"
                                    placeholder="Paste list of PolicyIDs"
                                    required
                                    rows={10}
                                    value={policySearchData || ""}
                                    width={12}
                                    onChange={(_e, { value }) => {
                                        if (policyIDs.length) return;
                                        setPolicySearchData(value as string);
                                    }}
                                />
                            )}
                            {selectedClientID && destinationPortfolioID && (
                                <Button
                                    compact
                                    icon="checkmark"
                                    size="tiny"
                                    type="button"
                                    content="Check Policies"
                                    disabled={
                                        loading || !policySearchData || !!policyIDs.length || !selectedClientID || !destinationPortfolioID
                                    }
                                    loading={loading}
                                    onClick={() => checkPolicies()}
                                />
                            )}
                        </Grid.Column>
                        {!!policyIDs.length && (
                            <Grid.Column width={4}>
                                <Header content="Options" style={{ fontSize: "12px" }} />
                                <Form.Checkbox
                                    label="Service start date (new policies)"
                                    checked={useStart}
                                    onChange={(_e, { checked }) => setUseStart(!!checked)}
                                />
                                <Message compact size="small" className="mt-0">
                                    {useStart && `Will use selected start date`}
                                    {!useStart && `Will use null start date`}
                                </Message>
                                <Form.Input
                                    width={12}
                                    required={useStart}
                                    label="Start Date"
                                    type="date"
                                    disabled={!useStart}
                                    value={serviceStartDate || ""}
                                    onChange={(_e, { value }) => setServiceStartDate(value)}
                                />
                                <Divider />
                                <Form.Checkbox
                                    label="Service end date (old policies)"
                                    checked={useEnd}
                                    onChange={(_e, { checked }) => setUseEnd(!!checked)}
                                />
                                <Message compact size="small" className="mt-0">
                                    {useEnd && `Will use selected end date`}
                                    {!useEnd && `Will use null end date`}
                                </Message>
                                <Form.Input
                                    width={12}
                                    required={useEnd}
                                    label="End Date"
                                    type="date"
                                    disabled={!useEnd}
                                    value={serviceEndDate || ""}
                                    onChange={(_e, { value }) => setServiceEndDate(value)}
                                />
                                <Divider />
                                <Form.Checkbox
                                    label="Mark old policies sold"
                                    checked={markSold}
                                    onChange={(_e, { checked }) => setMarkSold(!!checked)}
                                />
                                {selectedClientID === PRESTON_CLIENT_ID && (
                                    <Form.Checkbox
                                        label="Exclude files from Connect API"
                                        checked={exclude}
                                        onChange={(_e, { checked }) => setExclude(!!checked)}
                                    />
                                )}
                                {!!policyIDs.length && (
                                    <Button
                                        color="green"
                                        compact
                                        content="Copy Policies"
                                        disabled={copying || !!policyResultMap}
                                        icon="copy outline"
                                        loading={copying}
                                        size="tiny"
                                        type="submit"
                                    />
                                )}
                            </Grid.Column>
                        )}
                        {policyResultMap && (
                            <Grid.Column width={4}>
                                {policyResultMap.length > 0 && (
                                    <>
                                        <Header
                                            content={`Copy Results - ${policyResultMap.length} policies copied`}
                                            style={{ fontSize: "12px" }}
                                        />
                                        <Button
                                            className="mb-3"
                                            color="green"
                                            compact
                                            content="Export CSV"
                                            icon="file excel outline"
                                            onClick={handleCSVExport}
                                            size="tiny"
                                            type="button"
                                        />
                                        <div style={{ maxHeight: "75vh", overflowY: "scroll" }}>
                                            <Table celled compact="very" size="small">
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell content="OldPolicyID" />
                                                        <Table.HeaderCell content="NewPolicyID" />
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {policyResultMap.map((item) => {
                                                        return (
                                                            <Table.Row key={item.OldPolicyID}>
                                                                <Table.Cell>
                                                                    <PolicyLink id={item.OldPolicyID} name={item.OldPolicyID} />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <PolicyLink id={item.NewPolicyID} name={item.NewPolicyID} />
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        );
                                                    })}
                                                </Table.Body>
                                            </Table>
                                        </div>
                                    </>
                                )}
                            </Grid.Column>
                        )}
                    </Grid.Row>
                </Grid>
            </Form>
        </>
    );
};

export default PolicyCopy;
