import { Label, Message, Search, SearchResultProps } from "semantic-ui-react";
import React, { useEffect, useState } from "react";

import ClientLink from "./ClientLink";
import InsuredLink from "./InsuredLink";
import PolicyLink from "./PolicyLink";
import PortfolioLink from "./PortfolioLink";
import { groupBy } from "lodash";
import useDatabroker from "../hooks/useDatabroker";
import useDebounce from "../hooks/useDebounce";

type QuickSearchType = "Policy" | "Portfolio" | "Client" | "Insured";

interface QuickSearchOption {
    id: number;
    text: string;
    type: QuickSearchType;
}

type SearchOptionMap = { [key in QuickSearchType]?: SearchResultProps[] };
type QuickSearchResultMap = { [key in QuickSearchType]?: { name: string; results: SearchResultProps[] } };

const QuickSearch: React.FC = () => {
    const [searchValue, setSearchValue] = useState<string>("");
    const [searchResultsMap, setSearchResultsMap] = useState<QuickSearchResultMap>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const databroker = useDatabroker();
    const debouncedSearchValue = useDebounce(searchValue, 500);

    useEffect(() => {
        if (!debouncedSearchValue.trim() || debouncedSearchValue.length < 3) {
            setSearchResultsMap({});
            return;
        }
        (async () => {
            setLoading(true);
            const { data, error } = await databroker.sproc<QuickSearchOption>({
                objectName: "Meddiag.dbo.GetQuickSearch",
                parameters: { SearchText: debouncedSearchValue.trim() }
            });
            setLoading(false);
            if (error || !data) {
                setError(`Search error`);
                console.error(error);
                return;
            }

            const results: SearchResultProps[] = data.map((option) => ({
                text: option.text,
                resultid: option.id,
                title: option.type,
                key: option.id
            }));
            const groupedOptions = groupBy(results, "title") as SearchOptionMap;

            const searchResult = {};
            for (const key of Object.keys(groupedOptions)) {
                const type = key as QuickSearchType;
                const temp = {
                    name: type,
                    results: groupedOptions[type]
                };
                const newResult = { [type]: temp };
                Object.assign(searchResult, newResult);
            }
            setSearchResultsMap(searchResult);
        })();
    }, [databroker, debouncedSearchValue]);

    const resultRenderer = ({ text, title, resultid }: SearchResultProps) => {
        switch (title) {
            case "Client":
                return (
                    <Label
                        basic
                        className="link-opacity-7"
                        color="violet"
                        icon="building outline"
                        content={<ClientLink id={resultid} name={text || resultid.toString()} className="truncate" />}
                    />
                );
            case "Insured":
                return (
                    <Label
                        basic
                        className="link-opacity-7"
                        color="green"
                        icon="user outline"
                        content={<InsuredLink id={resultid} name={`${text} - ${resultid}`} className="truncate" />}
                    />
                );
            case "Policy":
                return (
                    <Label
                        basic
                        className="link-opacity-7"
                        color="blue"
                        icon="file outline"
                        content={<PolicyLink id={resultid} name={`${text} - ${resultid}`} className="truncate" />}
                    />
                );
            case "Portfolio":
                return (
                    <Label
                        basic
                        className="link-opacity-7"
                        color="teal"
                        icon="folder outline"
                        content={<PortfolioLink id={resultid} name={text || resultid.toString()} className="truncate" />}
                    />
                );
        }
        return <></>;
    };

    if (error) {
        return <Message size="small" icon="exclamation triangle" error content={error} onDismiss={() => setError(undefined)} />;
    }

    return (
        <Search
            aligned="right"
            category
            className="mr-3"
            loading={loading}
            minCharacters={3}
            onSearchChange={(e, { value }) => setSearchValue(value || "")}
            placeholder="Search"
            resultRenderer={resultRenderer}
            results={searchResultsMap}
            showNoResults={true}
            value={searchValue}
        />
    );
};
export default QuickSearch;
