import { Link } from "react-router-dom";
import React from "react";

interface ClientLinkProps {
    id: number;
    name: string;
    target?: string;
    className?: string;
}

const ClientLink: React.FC<ClientLinkProps> = ({ id, name, target, className }) => {
    return (
        <Link to={`/client/${id}`} target={target || "_blank"} className={className || "blue-link"}>
            {name}
        </Link>
    );
};

export default ClientLink;
