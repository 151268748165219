import { Link } from "react-router-dom";
import React from "react";

interface InsuredLinkProps {
    id: number;
    name: string;
    title?: string;
    target?: string;
    className?: string;
}

const InsuredLink: React.FC<InsuredLinkProps> = ({ id, name, target, title, className }) => {
    return (
        <Link to={`/insured/${id}`} target={target || "_blank"} title={title} className={className || "blue-link"}>
            {name}
        </Link>
    );
};

export default InsuredLink;
