import React, { createContext, useCallback, useState } from "react";
import { UserData, decode, hasAnyRole, hasRole } from "@itm21st/auth-core";
import { clearToken, getToken } from "../services/StorageProvider";

import { Role } from "../types";

interface AuthContextProps {
    user?: UserData;
    setUser: (user: UserData | undefined) => void;
    logout: () => void;
    userHasRole: (role: Role) => boolean;
    userHasAnyRole: (roles: Role[]) => boolean;
}
export const AuthContext = createContext<AuthContextProps>({
    setUser: () => {
        throw new Error("not implemented");
    },
    logout: () => {
        throw new Error("not implemented");
    },
    userHasRole: () => false,
    userHasAnyRole: () => false
});

interface AuthContextProviderProps {
    children: React.ReactNode;
}

const AuthContextProvider: React.FC<AuthContextProviderProps> = ({ children }: AuthContextProviderProps) => {
    const [user, setUser] = useState<UserData | undefined>(() => {
        const token = getToken();
        if (!token) {
            return undefined;
        }
        const decoded = decode(token);
        return decoded;
    });
    const logout = useCallback(() => {
        clearToken();
        setUser(undefined);
    }, []);

    const userHasRole = useCallback((role: Role) => !!user && hasRole(user, role, "connect"), [user]);
    const userHasAnyRole = useCallback((roles: Role[]) => !!user && hasAnyRole(user, roles, "connect"), [user]);

    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
                logout,
                userHasRole,
                userHasAnyRole
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
